<template>
  <div class="changelogtList">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <span>Entries can be edited for a maximum of 72 hours after creation</span>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(installationChangelogs)"
        :columns="kgm_responsiveColumns()"
        :filterable="false"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(installationChangelogs)"
        :sortable="{
          allowUnsort: true,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="changeTemplate"
          slot-scope="{props}"
        >
          <td class="chgCol">
            {{ props.dataItem.change && props.dataItem.change.length > 300 ? props.dataItem.change.substring(0, 300) : props.dataItem.change }}
            <p
              v-if="props.dataItem.change && props.dataItem.change.length > 300"
              class="alt-primary-color"
              style="cursor: pointer;"
              @click="openMoreModal(props.dataItem.change)"
            >
              more...
            </p>
          </td>
        </template>
        <template
          slot="dateTemplate"
          slot-scope="{props}"
        >
          <td>
            {{ dateTime_dateTime(kgm_getNestedValue(props.field, props.dataItem)) }}
          </td>
        </template>
        <template
          slot="imgOneCell"
          slot-scope="{props}"
        >
          <td>
            <div
              v-if="props.dataItem.images && props.dataItem.images.length > 0"
              class="row"
            >
              <img
                :src="`data:${props.dataItem.images[0].type ? props.dataItem.images[0].type : 'image/jpeg'};base64,${props.dataItem.images[0].content}`"
                style="max-width: 150px; cursor: pointer;"
                @click="openImgModal(props.dataItem.images[0])"
              >
            </div>
          </td>
        </template>
        <template
          slot="imgTwoCell"
          slot-scope="{props}"
        >
          <td>
            <div
              v-if="props.dataItem.images && props.dataItem.images.length > 1"
              class="row"
            >
              <img
                style="max-width: 150px; cursor: pointer;"
                :src="`data:${props.dataItem.images[1].type ? props.dataItem.images[1].type : 'image/jpeg'};base64,${props.dataItem.images[1].content}`"
                @click="openImgModal(props.dataItem.images[1])"
              >
            </div>
          </td>
        </template>
        <template
          slot="referenceCell"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <a
              v-if="props.dataItem.issue"
              target="_blank"
              class="alt-primary-color"
              :href="jiraUrl(props.dataItem.issue)"
            >
              {{ props.dataItem.issue }}
            </a>
            <a
              v-if="props.dataItem.fdTicket"
              target="_blank"
              class="alt-primary-color"
              :href="`https://alturos.freshdesk.com/a/tickets/${props.dataItem.fdTicket}`"
            >
              {{ props.dataItem.fdTicket }}
            </a>
          </td>
        </template>
        <div
          slot="creatorFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="creatorFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('creatorFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="changeFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="changeFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Value"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('changeFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="responsibleCreatorContactFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="responsibleCreatorContactFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Value"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('responsibleCreatorContactFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td
            :class="props.className"
          >
            <div
              class="btn-group"
            >
              <template v-if="checkDate(props.dataItem.date)">
                <button
                  class="btn btn-primary btn-sm"
                  @click="showSidebarEdit(props.dataItem)"
                >
                  <font-awesome-icon 
                    icon="edit"
                  />
                </button>
                <button
                  class="btn btn-danger btn-sm"
                  @click="() => {
                    indexToDelete = props.dataItem.id;
                    openConfirmRemoveModal();
                  }"
                >
                  <font-awesome-icon
                    icon="trash"
                  />
                </button>
              </template>
              <button
                class="btn btn-secondary btn-sm"
                @click="createUrl(props.dataItem.id)"
              >
                <font-awesome-icon
                  icon="external-link-alt"
                />
              </button>
            </div>
          </td>
        </template>
      </Grid>
    </template>
    <Sidebar
      v-if="showSidebar"
      :show-sidebar="showSidebar"
      @close="close"
    >
      <AddInstallationChangelog
        v-if="addingElement"
        :installation-id="installationId"
        @startLoading="loading = true;" 
        @reloadData="close(); getInstallationChangelogs()"
      />
      <EditInstallationChangelog
        v-else
        :installation-changelog="currentChangelog"
        @startLoading="loading = true;" 
        @reloadData="close(); getInstallationChangelogs()"
      />
    </Sidebar>
    <SweetModal
      ref="confirmDelete"
      icon="warning"
      blocking
      title="Delete cahngelog?"
      class="overflowHidden"
    >
      <p>Are you sure you want to delete this Changelog?</p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2 gray"
          icon="times"
        />
        <span>{{ $t('cancel') }}</span>
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeChangelog()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />
        <span>{{ $t('delete') }}</span>
      </button>
      <div class="clearfix" />
    </SweetModal>
    <SweetModal
      ref="moreModal"
      class="overflowHidden"
    >
      <p style="white-space: pre-line; text-align: initial;">
        {{ moreText }}
      </p>
      <div class="clearfix" />
    </SweetModal>
    <div
      v-if="currImg"
      class="imgOverlay"
      @click="closeOverlay()"
    >
      <font-awesome-icon
        class="black fa-2xl"
        icon="save"
        style="cursor: pointer;
              position: absolute;
              top: 20px;
              right: 45px;
              height: 20px;
              width: 20px;"
        @click="downloadImage()"
      />
      <font-awesome-icon
        class="black fa-2xl"
        icon="times"
        style="cursor: pointer;
              position: absolute;
              top: 20px;
              right: 20px;
              height: 20px;
              width: 20px;"
        @click="closeOverlay()"
      />
      <img
        v-if="currImg"
        :src="`data:${currImg.type ? currImg.type : 'image/jpeg'};base64,${currImg.content}`"
        style="overflow: hidden; object-fit: scale-down;"
        @click.stop
      >
    </div>
  </div>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { authenticationMixin } from '@/mixins/authenticationMixin';
import Sidebar from '@/components/Base/Sidebar.vue';
import AddInstallationChangelog from '@/components/Installation/AddInstallationChangelog.vue';
import EditInstallationChangelog from '@/components/Installation/EditInstallationChangelog.vue';
import { mapGetters } from 'vuex';
import { urlParseMixin } from '@/mixins/urlParseMixin';
import { SweetModal } from 'sweet-modal-vue';

export default {
  name: "InstallationChangelogList",
  components: {
    Sidebar,
    AddInstallationChangelog,
    EditInstallationChangelog,
    SweetModal
  },
  mixins: [
    kendoGridMixin,
    dateTimeMixin,
    authenticationMixin,
    urlParseMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      installationChangelogs: null,
      kgm_columns: [
        {
          field: 'date',
          filterable: true,
          filter: 'date',
          title: this.$t('date'),
          cell: 'dateTemplate',
          width: '120px',
          hideOn: ['mdDown']
        },
        {
          field: 'creatorName',
          filterable: true,
          filter: 'text',
          title: this.$t('creator'),
          filterCell: "creatorFilter",
          width: '120px',
          hideOn: ['mdDown']
        },
        {
          field: 'date',
          filterable: true,
          filter: 'date',
          title: this.$t('date'),
          cell: 'dateTemplate',
          width: '90px',
          hideOn: ['lgUp']
        },
        {
          field: 'creatorName',
          filterable: true,
          filter: 'text',
          title: this.$t('creator'),
          filterCell: "creatorFilter",
          width: '90px',
          hideOn: ['lgUp']
        },
        {
          field: 'topic',
          filterable: true,
          filter: 'text',
          title: this.$t('freshdesk.topic'),
          hideOn: ['mdDown'],
          width: '120px'
        },
        {
          field: 'change',
          filterable: true,
          filter: 'text',
          title: this.$t('changes'),
          filterCell: "changeFilter",
          cell: 'changeTemplate',
          minWidth: '400'
        },
        {
          filterable: false,
          title: 'Image 1',
          cell: "imgOneCell",
          hideOn: ['mdDown'],
          width: '150px'
        },
        {
          filterable: false,
          title: 'Image 2',
          cell: "imgTwoCell",
          hideOn: ['mdDown'],
          width: '150px'
        },
        {
          field: 'responsibleCreatorContact',
          filterable: true,
          filter: 'text',
          title: this.$t('responsibleCreatorContact'),
          filterCell: 'responsibleCreatorContactFilter',
          hideOn: ['xlDown'],
          width: '130px'
        },        
        {
          filterable: false, 
          title: 'Reference',          
          cell: "referenceCell",
          hideOn: ['xlDown'],
          width: '110px'
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'optionsTemplate',
          width: '132px'
        }
      ],
      showSidebar: false,
      addingElement: false,
      currentChangelog: null,
      indexToDelete: null,
      moreText: null,
      currImg: null
    }
  },
  computed: {
    ...mapGetters([
      'store_getJiraUrl'
    ]),
  },
  created () {
    this.getInstallationChangelogs();
  },
  methods: {
    createUrl (id) {
      var url = `${window.location.origin}/changelogs?id=${id}`;
      navigator.clipboard.writeText(url);
      this.$snotify.success("The URL has been copied to your clipboard.");
    },
    checkDate (date) {
      var dt = this.toMoment(date);
      var nDt = this.moment().add(-3, 'd');
      return dt > nDt;
    },
    openMoreModal (text) {
      this.moreText = text;
      this.$refs.moreModal.open();
    },
    openConfirmRemoveModal () {
      this.$refs.confirmDelete.open();
    },
    downloadImage () {
      let linkSource = `data:${this.currImg.type};base64,${this.currImg.content}`;
      let downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = this.currImg.name;
      downloadLink.click();
    },
    closeOverlay () {
      this.currImg = null;
    },
    openImgModal (data) {
      this.currImg = data;
    },
    jiraUrl (endpoint) {
      return this.urlParseMixin_combine(this.store_getJiraUrl, `browse/${ endpoint }`);
    },
    show () {
      this.showSidebar = true;
    },
    close () {
      this.showSidebar = false;
    },
    showSidebarAdd () {
      this.addingElement = true;
      this.show();
    },
    showSidebarEdit (data) {
      this.addingElement = false;
      this.currentChangelog = Object.assign({}, data); 
      this.show();
    },
    removeChangelog () {
      this.loading = true;
      this.axios.delete(`/Installation/RemoveInstallationChangelog?changelogId=${ this.indexToDelete }`)
        .then(() => {
          this.$refs.confirmDelete.close();
          this.getInstallationChangelogs()
          this.$snotify.success(this.$t('installationChangelog.deletedSuccessfully'));          
        })
        .finally(() => {
          this.indexToDelete = null;
          this.loading = true;
        });
    },
    getInstallationChangelogs () {
      this.loading = true;
      this.axios.get(`/Installation/GetInstallationChangelogs?installationId=${ this.installationId }`)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.installationChangelogs = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>
<style>
.changelogList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>

<style scoped>
.imgOverlay {
  width: 100%;
  height: 100%;
  background-color: rgba(211, 211, 211, 0.86);
  opacity: inherit;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10000;
  padding: 50px;
  display: flex;
  justify-content: center;
}

.chgCol {
  white-space: pre-line; 
  overflow-wrap: break-word;
}
</style>