<template>
  <div class="addInstallationChangelog">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          Changelog
        </h4>
        <hr class="m-0 mb-3">
        <label>{{ $t('date') }}</label>
        <date-picker
          v-model="changelog.date"
          :editable="true"
          :clearable="false"
          type="datetime"
          lang="en"
          format="DD.MM.YYYY hh:mm"
          value-type="date"
          class="ml-2"
          :first-day-of-week="1"
        />
        <div class="m-0 mb-3" />
        <label>{{ $t('creator') }}</label>
        <input
          v-model="changelog.creatorName"
          disabled
          class="form-control"
          type="text"
        >
        <div class="m-0 mb-3" />
        <label>{{ $t('freshdesk.topic') }}</label>
        <Multiselect
          id="topics"
          v-model="changelog.topic"
          placeholder="None"
          :options="topics"          
          :multiple="false"
          :taggable="true"
          :hide-selected="true"
          :searchable="false"
          :close-on-select="true"
        />
        <div class="m-0 mb-3 mt-3" />
        <label>{{ $t('changes') }}</label>
        <textarea
          v-model="changelog.change"
          rows="5"
          class="form-control"
          placeholder="Please document the change."
        />
        <div class="m-0 mb-3" />
        <label>{{ $t('responsibleCreatorContact') }}</label>
        <input
          v-model="changelog.responsibleCreatorContact"
          class="form-control"
          type="text"
        >
        <div class="m-0 mb-3" />
        <label>Issue</label>
        <input
          v-model="changelog.issue"
          class="form-control"
          type="text"
        >
        <div class="m-0 mb-3" />
        <label>FD Ticket</label>
        <input
          v-model="changelog.fdTicket"
          class="form-control"
          type="text"
        >
        <div class="m-0 mb-3" />
        <label>Images (max 2)</label>
        <div
          style="border: solid 1px #65656524; background-color: #65656524; min-height: 100px;"
          @dragover.prevent
          @drop.prevent
          @paste="onPaste"
        >
          <input
            id="assetsFieldHandle"
            type="file"
            multiple
            accept="image/*"
            style="display: none;"
            @change="uploadFile"
          >
          <label
            for="assetsFieldHandle"
            class="block cursor-pointer"
            style="width: 100%; text-align: center;"
          >
            <div>
              Copy, Drop files here or <u>click here</u> to upload files
            </div>
          </label>
          <div
            style="min-height: 80px; width: 100%;"
            @drop="dragFile"
          >
            <div v-if="files.length">
              <ul
                v-for="(file, index) in files"
                :key="`file-${index}`"
              >
                <li>
                  {{ file.name }} 
                  <font-awesome-icon
                    class="gray"
                    icon="trash"
                    style="cursor: pointer;"
                    @click="remove(files.indexOf(file))"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <button
        class="btn btn-primary float-right"
        @click.prevent="addInstallationChangelog()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t('add') }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import { errorMixin } from '@/mixins/errorMixin.js';
import { authenticationMixin } from '@/mixins/authenticationMixin';
export default {
  name: "AddInstallationChangelog",
  components: {
    DatePicker,
    Multiselect: () => import('vue-multiselect')
  },
   mixins: [
    errorMixin,
    authenticationMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      changelog: {
        date: new Date(),
        creatorName: this.authenticationGetUser().decodedPayload.name,
        topic: 'None'
      },
      files: [],
      topics: ['DestinationOS', 'Emma', 'James', 'MyServices', 'None', 'Peaksolution']
    }
  },
  methods: {
    onPaste (evt) {
      if(this.files.length < 2) {
        const clipboardItems = evt.clipboardData.items;
        const items = [].slice.call(clipboardItems).filter(function (item) {
            // Filter the image items only
            return item.type.indexOf('image') !== -1;
        });
        if (items.length === 0) {
            return;
        }

        for(var item of items) {
          const blob = item.getAsFile();
          if(this.files.length < 2) {
            this.files.push(blob);
          }
        }
      }
    },
    uploadFile (e) {
      for(var item of e.target.files) {
        if(this.files.length < 2) {
          this.files.push(item);
        }
      }
    },
    dragFile (e) {
      for(var item of e.dataTransfer.files) {
        if(this.files.length < 2) {
          this.files.push(item);
        }
      }
    },
    remove (i) {
      this.files.splice(i, 1);
    },
    async addInstallationChangelog () {
      const toBase64 = file => new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
      });

      let postObject = {
        'creatorName': this.changelog.creatorName,
        'change': this.changelog.change,
        'date': this.changelog.date,
        'responsibleCreatorContact': this.changelog.responsibleCreatorContact,
        'installationId': this.installationId,
        'issue': this.changelog.issue,
        'topic': this.changelog.topic === 'None' ? null : this.changelog.topic,
        'fdTicket': this.changelog.fdTicket,
        'images': [],
        'url': window.location.href
      }

      for(let file of this.files) {
        let b64 = await toBase64(file);
        postObject.images.push({
          'base64': b64,
          'name': file.name,
          'type': file.type
        });
      }

      await this.axios.post('/Installation/AddInstallationChangelog', postObject)
        .then((res) => {
          if(res) {
            this.$snotify.success(this.$t('installationChangelog.addedSuccessfully'));
            this.error_clear();
          }
           this.$emit("reloadData");
        })
        .catch((error) => {
          this.$emit("reloadData");
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>

<style scoped>
.addInstallationChangelog .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>